export const Enums = Object.freeze({
  FIELD_ID: "id",
  FIELD_ACCESS: "access",
  FIELD_VALUE_YES: "yes",
  LOCALSTORAGEURL: "enriqueUrl",
  LOCALSTORAGEUPDATEURL: "enriqueupdateURL",
  LOCAL_STORAGE_FILE_NAME: "storageFileName",
  COLLECTION_ADMIN: "admin",
  USERS: "users",
  ORDERS: "orders",
  ORDERSTATUS: "orderstatus",
  PRODUCTS: "products",
  UPDATE: "update",
  FREETRIAL: "freetrial",
  MENSUAL: "mensual",
  TRIMESTRIAL: "trimestrial",
  BIMENSUAL: "bimensual",
  INVENTORY: "inventory",
  INVENTORYHISTORY: "inventoryhistory",
  PROFILE_PRIVATE_KEY: "YKRygAKXmRjp1NxoveYa", // bio info
  REDIRECT_TO_HOME: "/",
  PROFILE: "profile",
  PROGRAM: "programs",
  EXERCISES: "exercises",
  COREPACKAGES: "corepackages",
  CLIENTS: "clients",
  CLIENT_ID: "clientId",
  MESSAGES: "messages",
  MESSAGES01: "messages01",
  BICEPS: "biceps",
  CUADRICEPS: "cuadriceps",
  DORSALES: "espalda",
  CORE: "core",
  GLUTEOSFEMORALES: "gluteosfemorales",
  HOMBROS: "hombros",
  MOVILIDAD: "movilidad",
  PIERNAS: "piernas",
  PECTORALES: "pectorales",
  TRICEPS: "triceps",
  ACCESORIOS: "accesorios",
  CALISTENIA: "calistenia",
  NOTIFICATION: "notification",
  FISIO: "fisio",
  FISIOHISTORY: "fisiohistory",
  SEND_NOTIFICATION_PUBLIC_KEY:
    "BMRPhYtMMscSYkSejdtK-GWc4j1cT492XjPc2mTuyfPRcCp4G5ZrQCFsff8rbg6iYB-cyv0LJwTIUL0KaexG1m8",
  LEVEL1: 10, // admin
  LEVEL2: 7, // trainer
  LEVEL3: 6, // fisio
  LEVEL4: 5, // nutri
  TRAINERNAME1: "Enrique Vargas",
  TRIANERNAME2: "Mary Maradiaga S",
});
