import React from "react";
import { useLocation, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
/*******************************
 * RequireAuth
 * exported to App.js
 * reference https://github.com/remix-run/react-router/blob/dev/examples/auth/src/App.tsx
 ******************************/
export default function RequestAuth({ children }) {
  const user = useSelector((state) => state.user.permission);
  let location = useLocation();
  
  if(!user){
    // provides a redirection to the login page, providing sucurity and 
    // good UX
    return <Navigate to={"/"} state={{from: location}} replace  />
  }

  return children;
}
