import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    permission: false,
    userData: [],
    clientId: null,
    exercise: "", // default path
    customers: [],
    ordersForStatus: [],
    customerSelected: null,
    exercisesSelected: [],
    repetitions: [],
    urlUpdate: "",
    level: 0,
  },
  reducers: {
    addUserCurrentState: (state, action) => {
      state.permission = action.payload.allow;
    },
    addUserData: (state, action) => {
      // replace the old array with the new array
      return { ...state, userData: action.payload };
    },
    addRepetitions: (state, action) => {
      return { ...state, repetitions: action.payload };
    },
    addClientId: (state, action) => {
      state.clientId = action.payload.client_id;
    },
    addExercise: (state, action) => {
      state.exercise = action.payload.item;
    },
    // use in Login.js line 82
    addPermissionLevel: (state, action) => {
      state.level = action.payload;
    },
    // appends new data, without deleting the previews entry
    addExercisesSelected: (state, action) => {
      return {
        ...state,
        exercisesSelected: [...state.exercisesSelected, ...action.payload],
      };
    },
    // appends new data, without deleting the previews entry
    addOrdersForStatus: (state, action) => {
      return {
        ...state,
        ordersForStatus: [...state.ordersForStatus, ...action.payload],
      };
    },
    resetExercisesSelected: (state) => {
      state.exercisesSelected = [];
    },
    resetRepetitions: (state) => {
      state.repetitions = [];
    },
    addCustomers: (state, action) => {
      return { ...state, customers: action.payload };
    },
    // implemented in ClientsUtilities.js
    addCustomerSelected: (state, action) => {
      return { ...state, customerSelected: action.payload };
    },
    addUrlUpdate: (state, action) => {
      state.urlUpdate = action.payload.url;
    },
  },
});

export const {
  addUserCurrentState,
  addUserData,
  addClientId,
  addExercise,
  addCustomers,
  addRepetitions,
  addCustomerSelected,
  addExercisesSelected,
  resetExercisesSelected,
  resetRepetitions,
  addUrlUpdate,
  addOrdersForStatus,
  addPermissionLevel,
} = userSlice.actions;

export default userSlice.reducer;

// initialState: [], the objects are created in Login.js
