import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./userSlice";
import databaseReducer from './databaseSlice'

const store = configureStore({
  reducer: {
    user: userReducer,
    database: databaseReducer,
  },
});

export default store;
