import React, { useEffect, useState, useCallback } from "react";
import { googleSignIn, auth } from "../../firebase/firebase_configuration";
import { useNavigate } from "react-router-dom";
import FirebaseModule from "../../firebase/FirebaseModule";
import { useDispatch } from "react-redux";
import {
  addUserCurrentState,
  addUserData,
  addPermissionLevel
} from "../../modules/redux/userSlice";
import { Enums } from "../../modules/enum/enums";
import LocationHref from "../../modules/functions/LocationHref";
import "./login.scss";
import "../button-styles/button-style.scss";

/***********************************
 * Login Component
 ***********************************/
export default function Login({ isOIS }) {
  const dispatch = useDispatch();
  const locationHome = LocationHref();
  const firebaseModule = FirebaseModule();
  const [runsOnce, setRunsOnce] = useState(false);
  const [buttonTitle, setButtonTitle] = useState("Ingresar");
  const admin = Enums.COLLECTION_ADMIN;
  const idFirebase = Enums.FIELD_ID;
  const home = Enums.REDIRECT_TO_HOME;
  const field_acess = Enums.FIELD_ACCESS;
  const field_value_yes = Enums.FIELD_VALUE_YES;
  const level2 = Enums.LEVEL2;
  let navigate = useNavigate();

  ////////////////////////////////////
  // functions
  const onHandlerAuthStateChange = useCallback(() => {
    auth.onAuthStateChanged((user) => {
      // console.log(user);
      if (!user) return;
      // let dataInfo = [{}] // is an object and invalid for firebase
      let userData = {
        id: user.uid,
        access: field_value_yes,
        fullName: user.displayName,
        email: user.email,
        photo: user.photoURL,
        verification: user.emailVerified,
        permissionLevel: +level2,
      };

      const userInFirebase = firebaseModule.checkDataInFirebase(
        admin,
        userData.id,
      );
      setButtonTitle("Verificando....");
      userInFirebase
        .then((snapShot) => {
          // console.log(dataIn);
          // new user
          if (!snapShot.exists()) {
            firebaseModule.addDataInFirebase(
              admin,
              userData.id,
              userData,
            );
            dispatch(addUserCurrentState({ allow: true }));
            dispatch(addUserData(userData));
            // addUserInFirebase("admin", userInfo[0].id, userInfo[0]);
            // current user
          } else if (snapShot.exists()) {
            const ref1 = firebaseModule.getDocFromFirebaseCompound(
              admin,
              idFirebase,
              userData.id,
              field_acess,
              field_value_yes,
            );
            // console.log(userData.id);
            ref1.then((res) => {
              const d = res.docs.map((items) => items.data());
              dispatch(addUserCurrentState({ allow: true }));
              dispatch(addUserData(d));
              dispatch(addPermissionLevel(d[0].permissionLevel));
              navigate("/main");
            });
          }
        })
        .catch((error) => {
          console.error(error.message);
          setButtonTitle("Acceso Denegado");
        });
    });
  }, [
    dispatch,
    admin,
    firebaseModule,
    idFirebase,
    field_acess,
    field_value_yes,
    navigate,
    level2
  ]);

  useEffect(() => {
    let isMounted = true;
    let timerId;

    if (runsOnce && isMounted) {
      onHandlerAuthStateChange();
      setRunsOnce(false);
    }
    if (buttonTitle === "Acceso Denegado") {
      timerId = setTimeout(() => {
        setButtonTitle("Admin Iniciar Sesión");
      }, 1000);
    }

    return () => {
      isMounted = false;
      clearTimeout(timerId);
    };
  }, [runsOnce, onHandlerAuthStateChange, buttonTitle, home, locationHome]);

  function handlerLoginAdmin(event) {
    event.preventDefault();
    setRunsOnce(true);
    googleSignIn();
  }
  /*************************************/
  return (
    <div className="login-component">
      <h4>Sistema de base de datos</h4>
      <h4>Iniciar con cuenta de Google</h4>
      <button
        className="login-button-style"
        onClick={(event) => handlerLoginAdmin(event)}
      >
        {buttonTitle}
      </button>
    </div>
  );
}
