import { createSlice } from "@reduxjs/toolkit";

export const databaseSlice = createSlice({
  name: "database",
  initialState: {
    currentCollection: "",
  },
  reducers: {
    addCurrentCollection: (state, action) => {
      state.currentCollection = action.payload.collection;
    },
  },
});

export const { addCurrentCollection } = databaseSlice.actions;

export default databaseSlice.reducer;
