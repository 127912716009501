import firebase from "firebase/compat/app";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage, deleteObject, getDownloadURL } from "firebase/storage";
import {
  GoogleAuthProvider,
  getAuth,
  setPersistence,
  browserSessionPersistence,
  signInWithPopup,
} from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import "firebase/database";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGE_SENDER_ID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
// const analytics = getAnalytics(app);
const provider = new GoogleAuthProvider();
// initialize cloud forestore and get a reference to the service
export const db = getFirestore(app);

// https://firebase.google.com/docs/auth/web/auth-state-persistence
export function googleSignIn() {
  setPersistence(auth, browserSessionPersistence).then(() => {
    return signInWithPopup(auth, provider);
  });
}
/////////////////////////////////////////////
// Storage
export const storage = getStorage(app);

// exported to ImageUpload.js
export function downloadUrlFromStorage(reference) {
  return getDownloadURL(reference);
}

export function deleteFile(reference) {
  return deleteObject(reference);
}
// export default firebase;
