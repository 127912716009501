export default function LocationHref() {
  // redirect the page directly to the home page
  // from the browser to clear the cookies
  // hard reset
  function onHandlerLocationHref(path) {
    window.location.href = path;
  }

  function handlerLocationHref(path) {
    return onHandlerLocationHref(path);
  }
  return {
    handlerLocationHref,
  };
}
